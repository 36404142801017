import revive_payload_client_4sVQNw7RlN from "/Users/shiroki/code/shirok1.dev/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/shiroki/code/shirok1.dev/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/shiroki/code/shirok1.dev/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/Users/shiroki/code/shirok1.dev/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/Users/shiroki/code/shirok1.dev/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/shiroki/code/shirok1.dev/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/Users/shiroki/code/shirok1.dev/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/shiroki/code/shirok1.dev/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_DXs5LYnWiu from "/Users/shiroki/code/shirok1.dev/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.mjs";
import unocss_MzCDxu9LMj from "/Users/shiroki/code/shirok1.dev/.nuxt/unocss.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/shiroki/code/shirok1.dev/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import carbon_G9hAzOGtP0 from "/Users/shiroki/code/shirok1.dev/plugins/carbon.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  preview_client_DXs5LYnWiu,
  unocss_MzCDxu9LMj,
  chunk_reload_client_UciE0i6zes,
  carbon_G9hAzOGtP0
]