<script setup lang="ts">
import "carbon-components/css/carbon-components.css";
// import "@fontsource/ibm-plex-sans/latin.css";
// import "@fontsource/ibm-plex-sans/latin-italic.css";
// import "@fontsource/ibm-plex-mono/latin.css";
// import "@fontsource/ibm-plex-mono/latin-italic.css";
useHead({
  htmlAttrs: {
    lang: 'en'
  },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} @ shirok1.dev` : 'shirok1.dev';
  }
})
</script>

<template>
  <div class="app flex flex-col min-h-screen" style="font-family: 'IBM Plex Sans';">
    <NavBar />
    <NuxtPage />
  </div>
</template>

<style lang="scss">
// @use "carbon-components/scss/globals/scss/styles.scss" with (
//   $prefix: 'bx',
//   $css--font-face: false,
//   // $css--reset: false,
// );

body {
  margin: 0;
}

main {
  flex-grow: 1;
}

a {
  word-break: break-words;
}
</style>
