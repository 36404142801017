import { default as _91_46_46_46slug_93mJiFNTzshhMeta } from "/Users/shiroki/code/shirok1.dev/pages/[...slug].vue?macro=true";
import { default as indexgPh38nAsiaMeta } from "/Users/shiroki/code/shirok1.dev/pages/index.vue?macro=true";
import { default as postsAWcjnrqzfVMeta } from "/Users/shiroki/code/shirok1.dev/pages/posts.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93mJiFNTzshhMeta?.name ?? "slug",
    path: _91_46_46_46slug_93mJiFNTzshhMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93mJiFNTzshhMeta || {},
    alias: _91_46_46_46slug_93mJiFNTzshhMeta?.alias || [],
    redirect: _91_46_46_46slug_93mJiFNTzshhMeta?.redirect,
    component: () => import("/Users/shiroki/code/shirok1.dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexgPh38nAsiaMeta?.name ?? "index",
    path: indexgPh38nAsiaMeta?.path ?? "/",
    meta: indexgPh38nAsiaMeta || {},
    alias: indexgPh38nAsiaMeta?.alias || [],
    redirect: indexgPh38nAsiaMeta?.redirect,
    component: () => import("/Users/shiroki/code/shirok1.dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: postsAWcjnrqzfVMeta?.name ?? "posts",
    path: postsAWcjnrqzfVMeta?.path ?? "/posts",
    meta: postsAWcjnrqzfVMeta || {},
    alias: postsAWcjnrqzfVMeta?.alias || [],
    redirect: postsAWcjnrqzfVMeta?.redirect,
    component: () => import("/Users/shiroki/code/shirok1.dev/pages/posts.vue").then(m => m.default || m)
  }
]