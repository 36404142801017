<template>
  <header>
    <div class="flex list-none p-0 m-2rem gap-1rem text-end justify-end items-end animate-fade-in animate-ease">
      <NuxtLink class="mr-auto color-bluegray-700" v-if="$route.path != '/'" to="/">
        shirok1.dev
      </NuxtLink>
      <NuxtLink to="/projects">projects</NuxtLink>
      <NuxtLink to="/posts">posts
      </NuxtLink>
      <NuxtLink to="/about">about</NuxtLink>
    </div>
  </header>
</template>

<style lang="scss" scoped>
a {
  color: inherit;
  text-decoration: none;
  // display: block;
  position: relative;
}

a::after {
  content: '';
  position: absolute;
  bottom: -0.1em;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: slategray;
  transition: opacity 300ms, transform 300ms;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
}

a:hover::after {
  opacity: 1;
  transform: scale(1);
}
</style>